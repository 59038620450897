import React, { useEffect } from 'react'
import Header from './Header'
import Vedio from '../../assets/images/bg-vedio.mp4';
import Footer from './Footer';
import About from '../../components/frontend/About';
import WhatWeDo from '../../components/frontend/WhatWeDo';
import Technology from '../../components/frontend/Technology';
import Testimonial from '../../components/frontend/Testimonial';
import IndustryServe from '../../components/frontend/IndustryServe';
import Trusted from '../../components/frontend/Trusted';



export default function Home() {
    return (
        <div>
            <Header
                isH1="Believe. Innovate. Inspire."
                isH2="We believe in people"
                isP="With a perfect blend of talent, technology, innovation, and expertise, we deliver cutting-edge digital solutions that help Enterprise, B2B, B2C, and SaaS companies achieve their unique business goals."
                BgComponent={VedioComponent}
                styleDrak=""
                isButtonName="Let's get started"
                isButtonUrl="/services"
            />
            <About />
            <WhatWeDo />
            <Technology />
            <Testimonial />
            <IndustryServe />
            <Trusted />
            <Footer />
        </div>
    )
}

export const VedioComponent = () => {
    return (
        <video id="background-video" autoPlay loop muted>
            <source src={Vedio} type="video/mp4" />
        </video>
    )
}
