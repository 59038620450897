import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

function Footer() {
    return (
        <div>
            {/* Footer */}
            <footer className=" text-lg-start" data-aos="fade-up">
                {/* Section: Links  */}
                <section className="">
                    <div className="footer-page-container-in container">
                        {/* Grid row */}
                        <div className="row mt-3">
                            {/* Grid column */}
                            <div className="footer-company col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                {/* Content */}
                                <h6 className="text-uppercase fw-bold mb-4 text-white">
                                    <i className="fas fa-gem me-3 "></i>Cynux Solutions Pvt. Ltd.
                                </h6>
                                <p>
                                We're here to build your next brand experience. Give us a shout.
                                </p>
                            </div>
                            {/* Grid column */}

                            {/* Grid column */}
                            <div className="footer-services col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4 text-white">
                                    Services
                                </h6>
                                <p>
                                    <HashLink to="/services#MobileAppDevelopment" className="text-white">App Development</HashLink>
                                </p>
                                <p>
                                    <HashLink to="/services#ProductDevelopment" className="text-white">Web Development</HashLink>
                                </p>
                                <p>
                                    <HashLink to="/services#ProductDevelopment" className="text-white">Ecommerce Development</HashLink>
                                </p>
                                <p>
                                    <HashLink to="/services#ProductDevelopment" className="text-white">Wordpress Development</HashLink>
                                </p>
                                <p>
                                    <HashLink to="/services#QualityAssurance" className="text-white">Product Testing</HashLink>
                                </p>
                                <p>
                                    <HashLink to="/services#UI-UX-Design" className="text-white">UI-UX Design</HashLink>
                                </p>
                            </div>
                            {/* Grid column */}

                            {/* Grid column */}
                            <div className="footer-links col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4 text-white">
                                    Company
                                </h6>
                                <p>
                                    <Link to="/services" className="text-white">Services</Link>
                                </p>
                                <p>
                                    <Link to="/about" className="text-white">About</Link>
                                </p>
                                <p>
                                    <Link to="/our-work" className="text-white">Our Work</Link>
                                </p>
                                <p>
                                    <Link to="/jobs" className="text-white">Careers</Link>
                                </p>
                                <p>
                                    <Link to="/contact" className="text-white">Contact us</Link>
                                </p>
                            </div>
                            {/* Grid column */}

                            {/* Grid column */}
                            <div className="footer-contact col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4 text-white">Contact</h6>
                                <p><i className="fa fa-building-o me-5"></i> TF 39, Amrapali Axiom, Bopal, Ahmedabad, Gujarat 380058</p>
                                <p>
                                    <i className="fa fa-envelope me-5"></i> info@cynuxsolutions.com
                                </p>
                                <p><i className="fa fa-phone me-5"></i> +91 8866302277</p>
                            </div>
                            {/* Grid column */}
                        </div>
                        {/* Grid row */}
                    </div>
                </section>
                {/* Section: Links  */}

                {/* Copyright */}
                <div className="text-center p-4 text-white">
                    © 2021 Copyright: <a >cynuxsolutions.com</a>
                </div>
                {/* Copyright */}
            </footer>
            {/* Footer */}
        </div>
    )
}

export default Footer