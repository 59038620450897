import companyLogo from '../../assets/images/logo.png';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Header = ({ isH1, isH2, isP, isButtonUrl, isButtonName, BgComponent, styleDrak }) => {
    const [isActive, setIsActive] = useState(false);
    const [isContent, setIsContent] = useState(true);
    const [isServicesActive, setIsServicesActive] = useState(false);
    const [isTextDark, setIsTextDark] = useState(false);
    useEffect(() => {
        if (styleDrak === 'text-dark') {
            setIsTextDark(true);
        } else {
            setIsTextDark(false);
        }
    }, []);

    const toggleMenu = () => {
        setIsActive(!isActive);
        setIsContent(!isContent);
    };
    const toggleServicesMenu = () => {
        setIsServicesActive(!isServicesActive);
    };

    return (
        <div className="main-header">
            <ToastContainer />
            <div className="">
                <BgComponent />
                <HeaderComponent toggleMenu={toggleMenu} isActive={isActive} isTextDark={isTextDark} isServicesActive={isServicesActive}  toggleServicesMenu={toggleServicesMenu}  />
            </div>
            <div className={`container home-content`} data-aos="fade-up">
                <h1 className={`${isTextDark ? 'text-dark' : ''} ${isContent ? '' : 'remove-header-content'}`}>{isH1}</h1>
                <h2 className={`${isTextDark ? 'text-dark' : ''} ${isContent ? '' : 'remove-header-content'}`}>{isH2}</h2>
                <p className={`${isTextDark ? 'text-dark' : ''} ${isContent ? '' : 'remove-header-content'}`}>{isP}</p>
                {(isButtonUrl.includes('#')) ?
                    <a href={`${isButtonUrl}`} className={` ${isTextDark ? 'btn btn-info' : 'btn btn-info'} ${isContent ? '' : 'remove-header-content'} ${isButtonName === '' ? 'd-none' : ''}`}>{isButtonName === '' ? '' : isButtonName}</a>
                    :
                    <Link to={`${isButtonUrl}`} className={` ${isTextDark ? 'btn btn-info' : 'btn btn-info'} ${isContent ? '' : 'remove-header-content'} ${isButtonName === '' ? 'd-none' : ''}`}>{isButtonName === '' ? '' : isButtonName}</Link>
                }
            </div>
        </div >
    )
}

export default Header;

function HeaderComponent({ toggleMenu, isActive, isTextDark, isServicesActive, toggleServicesMenu }) {

    return (
        <nav className="out-header">
            <div className='header-fixed'>
                <div className="container header pt-3">
                    <div className="header-left">
                        <Link to="/" ><img src={companyLogo} width="50vw" alt="" /></Link>
                    </div>
                    <div className="header-right laptop">
                        <ul>
                            {/* <li><Link className={`${isTextDark ? 'text-dark' : ''}`} to="/">home</Link></li> */}
                            <li><Link className={`${isTextDark ? 'text-dark' : ''}`} to="/our-work">work</Link></li>
                            <li className='dropdown'>
                                <Link className={`dropbtn ${isTextDark ? 'text-dark' : ''}`} to="/services">expertise</Link>
                                <div className="dropdown-content text-dark" >
                                    <div className="">
                                        <div className="row pl-4">
                                            <div className="col-md-3 services-dropdown-heading">
                                                <h1>Product Services for Startups and Brands</h1>
                                            </div>
                                            <div className="col-md-3">
                                                <HashLink to="/services#UI-UX-Design">
                                                    <div className="service-menu">
                                                        <i className='fa fa-newspaper-o fa-lg'></i>
                                                        <h5>UI UX Development</h5>
                                                    </div>
                                                </HashLink>
                                                <HashLink to="/services#MobileAppDevelopment">
                                                    <div className="service-menu">
                                                        <i className='fa fa-cogs fa-lg'></i>
                                                        <h5>Mobile Development</h5>
                                                    </div>
                                                </HashLink>
                                            </div>
                                            <div className="col-md-3">
                                                <HashLink to="/services#MobileAppDevelopment">
                                                    <div className="service-menu">
                                                        <i className='fa fa-cogs fa-lg'></i>
                                                        <h5>Web Development</h5>
                                                    </div>
                                                </HashLink>
                                                <HashLink to="/services#ProductTesting">
                                                    <div className="service-menu">
                                                        <i className='fa fa-newspaper-o fa-lg'></i>
                                                        <h5>Product Testing</h5>
                                                    </div>
                                                </HashLink>
                                            </div>
                                            <div className="col-md-3">
                                                <HashLink to="/services#QualityAssurance">
                                                    <div className="service-menu">
                                                        <i className='fa fa-newspaper-o fa-lg'></i>
                                                        <h5>Quality Assurance</h5>
                                                    </div>
                                                </HashLink>
                                                <HashLink to="/services#MobileAppDevelopment">
                                                    <div className="service-menu">
                                                        <i className='fa fa-cogs fa-lg'></i>
                                                        <h5>Ecommerce Development</h5>
                                                    </div>
                                                </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><Link className={`${isTextDark ? 'text-dark' : ''}`} to="/about">about</Link></li>
                            
                            <li><Link className={`${isTextDark ? 'text-dark' : ''}`} to="/jobs">careers</Link></li>
                            
                            <li><Link className="btn btn-info btn-info" to="/contact">contact</Link></li>
                        </ul>
                    </div>
                    <div onClick={toggleMenu} className="header-right mobile">
                        <i className="fa fa-bars menu-btn text-dark"></i>
                    </div>
                </div>
            </div>
            {/* <hr /> */}
            <div className={`mobile-menus ${isActive ? 'active' : 'inactive'}`} >
                <div className="container" >
                    <ul data-aos="fade-down">
                        {/* <li><Link to="/">home</Link></li> */}
                        <hr />
                        <li>
                            <Link className='drop-btn-mobile' to="/services" onClick={toggleServicesMenu}>expertise</Link>
                            <div className={`container service-dropdown-mobile ${isServicesActive ? 'active' : 'inactive'}`}>
                                <hr />
                                <li><Link to="/our-work"><i className='fa fa-arrow-right'></i> UI UX Development</Link></li>
                                <hr />
                                <li><Link to="/our-work"><i className='fa fa-arrow-right'></i> Mobile Development</Link></li>
                                <hr />
                                <li><Link to="/our-work"><i className='fa fa-arrow-right'></i> Web Development</Link></li>
                                <hr />
                                <li><Link to="/our-work"><i className='fa fa-arrow-right'></i> Product Testing</Link></li>
                                <hr />
                                <li><Link to="/our-work"><i className='fa fa-arrow-right'></i> Quality Assurance</Link></li>
                                <hr />
                                <li><Link to="/our-work"><i className='fa fa-arrow-right'></i> Ecommerce Development</Link></li>
                            </div>
                        </li>
                        <hr />
                        <li><Link to="/our-work">work</Link></li>
                        <hr />
                        <li><Link to="/jobs">careers</Link></li>
                        <hr />
                        <li><Link to="/about">about</Link></li>
                        <hr />
                        <li><Link to="/contact">contact</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
