import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Master from './layouts/frontend/Home';
import Services from './components/frontend/Services';
import OurWork from './components/frontend/OurWork';
import Jobs from './components/frontend/Jobs';
import AboutUs from './components/frontend/AboutUs';
import ContactUs from './components/frontend/ContactUs';
import WebDevelopment from './components/frontend/WebDevelopment';
import MobileDevelopment from './components/frontend/MobileDevelopment';
import UiUxDesign from './components/frontend/UiUxDesign';
import 'aos/dist/aos.css'
import AOS from 'aos'
import NotFound from './layouts/frontend/NotFound';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return null;
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div>
        <Routes>
          <Route path='/' element={<Master />} />
          <Route path='/services' element={<Services />} />
          <Route path='/our-work' element={<OurWork />} />
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/portfolio/web-dev-case-study' element={<WebDevelopment />} />
          <Route path='/portfolio/mobile-dev-case-study' element={<MobileDevelopment />} />
          <Route path='/portfolio/uiux-dev-case-study' element={<UiUxDesign />} />
          <Route path='/our-work/portfolio/web-dev-case-study' element={<WebDevelopment />} />
          <Route path='/our-work/portfolio/mobile-dev-case-study' element={<MobileDevelopment />} />
          <Route path='/our-work/portfolio/uiux-dev-case-study' element={<UiUxDesign />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
