import React from 'react'
import Header from '../../layouts/frontend/Header'
import Footer from '../../layouts/frontend/Footer'
import { Link } from 'react-router-dom'
import ImgAirAccess from '../../assets/images/airaccess.png'

function MobileDevelopment() {
    return (
        <div>
            <Header
                isH1="FareTrak: Bus Ticket & Traking System"
                isH2=""
                isP="FareTrak is a ticketing system that supports closed (from to) and open (tap on tap off) loop operations. With an easy use interface that runs on either Android or iOS FareTrak is configured directly from the Operators reference data no setup is needed. FareTrak has a full suite of reporting and  retrieves operational data in near real time allowing for fast adoption of scheduling changes."
                BgComponent={WebComponent}
                styleDrak="text-dark"
                isButtonName=""
                isButtonUrl=""
            />
            <div className=" case-study-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 text-center"  data-aos="fade-right">
                            <h1>FareTrak</h1>
                        </div>
                        <div className="col-md-6 col-sm-12" data-aos="fade-left">
                            <h5>Air Access is an Australian technology company that brings high fidelity planning, analytics and ticketing to the transit sector.</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center" data-aos="fade-up">
                            <img src={ImgAirAccess} alt="AirAccess Technologies" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 text-left"  data-aos="fade-right">
                            <h1 data-aos="fade-up">We don’t sell tickets.
                                We create experiences.</h1>
                            <div className="case-study-points" data-aos="fade-up">
                                <h3>Industry</h3>
                                <p>Transit Sector</p>
                            </div>
                            <div className="case-study-points" data-aos="fade-up">
                                <h3>Technologies
                                </h3>
                                <p>PHP, MySQL, Kotlin, Swift</p>
                            </div>
                            <div className="case-study-points" data-aos="fade-up">
                                <h3>Services</h3>
                                <p>Application Development, Support & Maintenance</p>
                            </div>
                            <div className="case-study-points" data-aos="fade-up">
                                <Link to="https://www.airaccess.com.au/" target="_blank" className='text-info'>VIEW WEBSITE <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" >
                            <div className="case-study-points">
                                <h3 data-aos="fade-left">The Brief</h3>
                                <p className='mt-4' data-aos="fade-left">Air Access is an Australian technology company that brings high fidelity planning, analytics and ticketing to the transit sector. <br/><br/>Based on industry standards and decades experience in the delivery of high availability, robust fit for purpose software the Air Access technology solution is easy to use, highly scalable and accessible from any web browser.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-left">
                            <div className="what-we-did-case-study">
                                <h1  data-aos="fade-right">What we did?</h1>
                                <h3>Background:</h3>
                                <p data-aos="fade-right">Before the introduction of the FareTrak Bus Ticket and Tracking System, passengers faced numerous challenges such as uncertainty about bus arrival times, long wait times at bus stops, and the inconvenience of purchasing tickets in advance. AirAccess Technologies recognized an opportunity to leverage mobile technology and real-time data to address these issues and improve the overall commuting experience for passengers.</p>
                                <h3>Solution:</h3>
                                <p data-aos="fade-right">AirAccess Technologies collaborated with a team of experienced developers to create a comprehensive solution comprised of three key components:</p>
                                <ol data-aos="fade-up" className='ol-solutions'>
                                    <li><b>Mobile Applications:</b></li>
                                    <ul>
                                        <li><b>Android App (Developed in Kotlin):</b> The FareTrak Android app was developed using Kotlin, a modern programming language for Android app development. Kotlin's concise syntax, robustness, and interoperability with Java made it an ideal choice for building feature-rich and user-friendly mobile applications. </li>
                                        <li><b>iOS App (Developed in Swift):</b> The FareTrak iOS app was developed using Swift, Apple's programming language for iOS app development. Swift's performance, safety features, and ease of use facilitated the creation of an intuitive and seamless user experience for iPhone and iPad users.</li>
                                        <li><b>Features:</b> Both the Android and iOS apps offered a range of features, including:</li> 
                                        <ul className='ul-features'>
                                            <li><b>Ticket Purchasing:</b> Passengers could easily browse bus routes, check schedules, and purchase tickets securely using various payment methods, including credit/debit cards and mobile wallets.</li>
                                            <li><b>Real-Time Bus Tracking:</b> Users could track the real-time location of buses on a map, enabling them to plan their journeys more effectively and minimize wait times at bus stops.</li>
                                            <li><b>Notifications:</b> The apps provided users with timely notifications about upcoming departures, delays, and other relevant information to keep them informed throughout their journey.</li>
                                        </ul>                                   
                                    </ul><br/>
                                    <li><b>Backend System</b>
                                    <ul>
                                        <li><b>Database Management: </b>The FareTrak backend system, developed using PHP, interacted with a relational database management system (e.g., MySQL) to store and retrieve data related to bus routes, schedules, ticket inventory, user accounts, and bus locations.</li>
                                        <li><b>API Integration: </b>PHP facilitated the development of RESTful APIs that allowed the mobile apps to communicate with the backend server, enabling seamless data exchange for ticket purchases, real-time bus tracking, and user authentication.</li>
                                        <li><b>Admin Dashboard: </b>The backend system included an admin dashboard where bus operators could manage bus routes, update schedules, monitor ticket sales, and analyze performance metrics in real-time.</li>
                                        </ul>
                                    </li>
                                </ol>
                                <h3 data-aos="fade-right">Outcome:</h3>
                                <p data-aos="fade-left">The implementation of the FareTrak Bus Ticket and Tracking System delivered significant benefits for passengers and bus operators:</p>
                                <ul data-aos="fade-up" className='ol-solutions'>
                                    <li><b>Enhanced Passenger Experience: </b>Passengers enjoyed a more convenient and seamless commuting experience, with the ability to purchase tickets on-the-go, track bus locations in real-time, and receive timely notifications about departures and delays.</li>
                                    <li><b>Improved Operational Efficiency:</b>Bus operators benefited from enhanced visibility into bus operations, optimized route planning, and improved resource allocation, resulting in reduced wait times, better schedule adherence, and increased passenger satisfaction.</li>
                                    <li><b>Data-Driven Insights:</b>The FareTrak backend system provided valuable insights and analytics to AirAccess Technologies and bus operators, enabling data-driven decision-making, continuous service improvements, and targeted marketing strategies.</li>
                                    <li><b>Increased Revenue: </b>By offering a more user-friendly and reliable transportation service, AirAccess Technologies and bus operators were able to attract more passengers, increase ticket sales, and generate additional revenue streams.</li>
                                </ul>
                                <h3 data-aos="fade-right">Conclusion:</h3>
                                <p data-aos="fade-left">The FareTrak Bus Ticket and Tracking System, developed using Kotlin for Android, Swift for iOS, and PHP for the backend, transformed the public transportation experience by combining seamless ticket purchasing with real-time bus tracking capabilities. By leveraging cutting-edge technology and innovative design, we successfully addressed the challenges faced by passengers and bus operators, paving the way for a more efficient, convenient, and enjoyable commuting experience for all stakeholders involved.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="testimonial-case-study"  data-aos="zoom-in">
                        <h1>“Cynux Solutions Pvt Ltd was able to deliver accurate and high-quality estimates for the project, much to the client's delight. The team was highly responsive, and internal stakeholders were particularly impressed with the vendor's cost-effective solutions.”</h1>
                        <p>CTO, Air Access Technology</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MobileDevelopment

export const WebComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}