import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div className='bg-dark text-light ' style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='container text-center'>
                <div>
                    <h1 style={{ fontSize: 'xxx-large', fontWeight: '800' }}>404 Not Found</h1>
                    <p style={{ fontSize: 'x-large', fontWeight: '600' }}>Sorry, an error has occured, Requested page not found!</p>
                    <Link to='/' className='btn btn-info'>
                        <i className='fa fa-arrow-left' style={{ fontSize: 'large', fontWeight: '500' }}> BACK HOME</i>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound