import React from 'react'
import Header from '../../layouts/frontend/Header'
import Footer from '../../layouts/frontend/Footer';
import { Link } from 'react-router-dom';
import ImgC1 from '../../assets/images/clients/client-1.png';
import ImgC2 from '../../assets/images/clients/client-2.png';
import ImgC3 from '../../assets/images/clients/client-3.png';
import ImgC4 from '../../assets/images/clients/client-4.png';
import ImgC5 from '../../assets/images/clients/client-5.png';
import ImgC6 from '../../assets/images/clients/client-6.png';
import ImgUIUX from '../../assets/images/UI-UX-Design.png';
import ImgPD from '../../assets/images/product_development.jpeg';
import ImgMD from '../../assets/images/mobile_app.jpeg';
import ImgQA from '../../assets/images/qa.jpg';

export default function Services() {
    let myStyle = {
        color: '#7e8085'
    };
    let isP = (
        <>
            <div style={myStyle}>
                We love turning concepts into reliable and robust digital solutions, with a well defined
                strategy, amazing user experience and scalable technology solutions.
            </div>
        </>
    );

    return (
        <div>
            <Header
                isH1="Creating digital products that solve real-life problems."
                isH2=""
                isP={isP}
                BgComponent={ServiceComponent}
                styleDrak="text-dark"
                isButtonName="All Services"
                isButtonUrl="/services#prductservicestartup"
            />
            <div id='prductservicestartup' className='services-container'>
                <div className="container service-part-1 pt-5">
                    <div className="row">
                        <div className="col-md-6 col-sm-12"  data-aos="fade-right">
                            <h1>Product Services for Startups and Brands</h1>
                        </div>
                        <div className="col-md-6 col-sm-12"  data-aos="fade-left">
                            <div>
                                <h3>How we define, innovate and launch our products</h3>
                                <p>We help you understand your customers better and develop well-designed products and experiences. From product vision and development to software maintenance, we’ve got you covered.</p>
                                <Link to="/our-work" className='text-info'>Explore the Process <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------UI/UX Design-------------------- */}
                <div id="UI-UX-Design" className="container service-part-2 mt-3" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h3>UI/UX Design.</h3>
                            <p className='mb-4'>We design beautiful, easy-to-use mobile and web products that meet your business and users needs. <br />
                                We also work close to our dev team to ensure our designs are technically feasible within the constraints of the project.</p>
                            <Link to="#" className='text-dark mb-3'><i className='fa fa-long-arrow-right text-info'></i> Information Architecture</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> User Experience Design</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Visual Design</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Prototyping</Link>
                        </div>
                        <div className="col-md-6 service-img-1 col-sm-12">
                            <img src={ImgUIUX} alt="" srcset="" />
                            <p>Curated content helps brides get one step closer to their dream wedding each day.</p>
                            <div className="read-more-case-study">
                                <Link to="/contact" className='text-info'>Let's get started ... <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---------------------Product Development-------------------- */}
                <div id="ProductDevelopment" className="container service-part-2 mt-5" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-6 service-img-1 col-sm-12">
                            <img src={ImgPD} alt="" srcset="" />
                            <p>Social Media Networking Site for Real Influencers & Real People.</p>
                            <div className="read-more-case-study">
                                <Link to="/contact" className='text-info'>Let's get started ... <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h3>Product Development</h3>
                            <p className='mb-4'>We design beautiful, easy-to-use mobile and web products that meet your business and users needs. <br />
                                We also work close to our dev team to ensure our designs are technically feasible within the constraints of the project.</p>
                            <Link to="#" className='text-dark mb-3'><i className='fa fa-long-arrow-right text-info'></i> Product Discovery</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Product Strategy</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Custom Software Development</Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Enterprise Product Development</Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> SaaS & Mobility Enablement</Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> MVP Development</Link>
                        </div>
                    </div>
                </div>

                {/* --------------------Mobile App Development--------------------- */}
                <section id="MobileAppDevelopment" className="container service-part-2 mt-5"  data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h3>Mobile App Development</h3>
                            <p className='mb-4'>We deliver incredible mobile experiences with native iOS and Android and Hybrid applications. <br />
                                And when it comes to cross-platform solutions, our expertise and technology provide a good balance between development efficiency and the performance and feel of a native & hybrid application.</p>
                            <Link to="#" className='text-dark mb-3'><i className='fa fa-long-arrow-right text-info'></i> iOS Development
                            </Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Android Development
                            </Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> React Native Development
                            </Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Flutter Development</Link>
                        </div>
                        <div className="col-md-6 service-img-1 col-sm-12">
                            <img src={ImgMD} alt="" srcset="" />
                            <p>Simple, convenient, and hassle-free tele-health solutions through audio-video consulting from around the world.</p>
                            <div className="read-more-case-study">
                                <Link to="/contact" className='text-info'>Let's get started ... <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* --------------------Quality Assurance--------------------- */}
                <div id="QualityAssurance" className="container service-part-2 mb-5 mt-5" data-aos="fade-up">
                    <div className="row">
                        <div className="col-md-6 service-img-1 col-sm-12">
                            <img src={ImgQA} alt="" srcset="" />
                            <p>Social Media Networking Site for Real Influencers & Real People.</p>
                            <div className="read-more-case-study">
                                <Link to="/contact" className='text-info'>Let's get started ... <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h3>Quality Assurance</h3>
                            <p className='mb-4'>Testing and quality control are core parts of delivering a great product and help us make sure nothing slips between the cracks. <br />
                                Improving the quality of your software without affecting your delivery timeframe. We provide QA testing services for web and mobile applications, ensuring full compliance and high-quality software every time.</p>
                            <Link to="#" className='text-dark mb-3'><i className='fa fa-long-arrow-right text-info'></i>
                                Test Automation</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Product Strategy</Link><br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i>
                                Performance Testing</Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> User testing</Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Security Testing
                            </Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> Mobile Testing
                            </Link>
                            <br />
                            <Link to="#" className='text-dark mb-1'><i className='fa fa-long-arrow-right text-info'></i> A/B Testing
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='services-container our-client-on-services pb-5'>
                <div className="container service-part-1 pt-5">
                    <div className="row m-3">
                        <div className="col-md-6 col-sm-12"  data-aos="fade-right">
                            <h1>Our Clients</h1>
                        </div>
                        <div className="col-md-6 col-sm-12"  data-aos="fade-left">
                            <div>
                                <h3>We helped bold startups and product companies design and create apps that users love</h3>
                                <p>With years of experience, we have developed the process, system and technologies that enable us to create high-quality, scalable web and mobility solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container our-clients-logos-container'  data-aos="fade-up">
                    <div className='our-clients-logos-container-in border'>
                        <img src={ImgC1} alt="" />
                    </div>
                    <div className='our-clients-logos-container-in border'>
                        <img src={ImgC2} alt="" />
                    </div>
                    <div className='our-clients-logos-container-in border'>
                        <img src={ImgC3} alt="" />
                    </div>
                    <div className='our-clients-logos-container-in border'>
                        <img src={ImgC4} alt="" />
                    </div>
                    <div className='our-clients-logos-container-in border'>
                        <img src={ImgC5} alt="" />
                    </div>
                    <div className='our-clients-logos-container-in border'>
                        <img src={ImgC6} alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export const ServiceComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}

