import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Footer from '../../layouts/frontend/Footer';
import Header from '../../layouts/frontend/Header'
import { toast } from 'react-toastify';
import ImgIndia from '../../assets/images/india.png';
import ImgFlagIndia from '../../assets/images/flag-india.png';


function ContactUs() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e);
        emailjs
            .sendForm('service_thx1h4b', 'template_0c6e6kk', form.current, {
                publicKey: 'Hlj8SEVbb5hPeC38O',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    form.current.reset();
                    toast.success('Message sent successfully!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    toast.error('Failed to send message.');
                },
            );
    };
    return (
        <div>
            <Header
                isH1="Let’s build"
                isH2="something awesome
                together."
                isP=""
                BgComponent={ContactUsComponent}
                styleDrak="text-dark"
                isButtonName=""
                isButtonUrl=""
            />
            <div className="contact-page-container">
                <div className="container">
                    <div className="contact-page-form">
                        <div className="contact-page-form-in-1">
                            <h1>How can we help?</h1>
                            <i className='fa fa-paper-plane-o fa-3x'></i>
                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="contact-page-form-in-2">
                                <div data-aos="fade-up">
                                    <label htmlFor="Name" className=''>Full Name*</label>
                                    <input required type="text" className="form-control " id='Name' name='User_name' placeholder='Your Name' />
                                </div>
                                <div data-aos="fade-up">
                                    <label htmlFor="Mobile" className='mt-4'>Phone number*</label>
                                    <input required type="text" className="form-control" id='Mobile' name='User_mobile' placeholder='Your contact number' />
                                </div>
                                <div data-aos="fade-up">
                                    <label htmlFor="Email" className='mt-4'>Email address*</label>
                                    <input required type="email" className="form-control" id='Email' name='User_email' placeholder='Your Email address' />
                                </div>
                                <label data-aos="fade-up" htmlFor="Email" className='mt-4'>Services you are looking for*</label><br />
                                <div className="checkboxes-container-contact">
                                    <div data-aos="fade-up" className='checkbox'>
                                        <input type="checkbox" className='form-check-input' id='Checkbox' value='UI/UX Design' name='Checkbox' />
                                        <label htmlFor="Email" className=''>UI/UX Design</label>
                                    </div>
                                    <div data-aos="fade-up" className='checkbox'>
                                        <input type="checkbox" className='form-check-input' id='Checkbox' value='Product Development' name='Checkbox' />
                                        <label htmlFor="Email" className=''>Product Development</label>
                                    </div>
                                    <div data-aos="fade-up" className='checkbox'>
                                        <input type="checkbox" className='form-check-input' id='Checkbox' value='Web Development' name='Checkbox' />
                                        <label htmlFor="Email" className=''>Web Development</label>
                                    </div>
                                    <div data-aos="fade-up" className='checkbox'>
                                        <input type="checkbox" className='form-check-input' id='Checkbox' value='Mobile Development' name='Checkbox' />
                                        <label htmlFor="Email" className=''>Mobile Development</label>
                                    </div>
                                    <div data-aos="fade-up" className='checkbox'>
                                        <input type="checkbox" className='form-check-input' id='Checkbox' value='QA & Testing' name='Checkbox' />
                                        <label htmlFor="Email" className=''>QA & Testing</label>
                                    </div>
                                    <div data-aos="fade-up" className='checkbox'>
                                        <input type="checkbox" className='form-check-input' id='Checkbox' value='Resource Augmentation' name='Checkbox' />
                                        <label htmlFor="Email" className=''>Resource Augmentation</label>
                                    </div>
                                </div>
                                <div data-aos="fade-up">
                                    <label htmlFor="ProjectStartDate" className='mt-4'>Project start date*</label>
                                    <input required type="text" className="form-control" id='ProjectStartDate' name='Project_Start_Date' placeholder='Start immediately or Start in a month'/>
                                </div>
                                <div data-aos="fade-up">
                                    <label htmlFor="Budget" className='mt-4'>Budget range(USD)*</label>
                                    <input required type="text" className="form-control" id='Budget' name='User_budget' placeholder='5k - 10k'  />
                                </div>
                                <div data-aos="fade-up">
                                    <label htmlFor="ProjectDetails" className='mt-4'>Project details*</label>
                                    <textarea required rows="5" cols="50" type="textarea" className="form-control" id='ProjectDetails' name='Project_Details' placeholder='Share inputs about the project'/>
                                </div>
                                <div data-aos="fade-up">
                                    <input type="submit" value="Send Inquiry" className='btn btn-info mt-4' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="contact-page-address">
                <div className="container">
                    <h1 data-aos="zoom-in-down">OUR OFFICE</h1>
                    <div className="contact-address-div">
                        
                        <div className="address-2"  data-aos="fade-left">
                            <img src={ImgIndia} alt="" />
                            <div data-aos="fade-up">
                                <img src={ImgFlagIndia} alt="" />
                                <h3> INDIA</h3>
                            </div>
                            <p data-aos="fade-up">
                                TF 39, Amrapali Axiom, Bopal, <br/>
                                Ahmedabad, Gujarat - 380058<br/>
                                hello@cynuxsolutions.com  <br />
                                +91 886 630 2277  <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs

export const ContactUsComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}