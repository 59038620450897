import React from 'react'
import Footer from '../../layouts/frontend/Footer';
import Header from '../../layouts/frontend/Header'
import { Link } from 'react-router-dom';
import ImgFareTrak from '../../assets/images/faretrak.png';

function OurWork() {
    
    return (
        <div>
            <Header
                isH1="Bringing Cutting-Edge technology to help our partners grow"
                isH2=""
                isP=""
                BgComponent={OurWorkComponent}
                styleDrak="text-dark"
                isButtonName=""
                isButtonUrl=""
            />
            <div className="our-work-container"  data-aos="fade-up">
                <div className="container pt-5">
                    <div className="our-work-container-in">
                        <div className="our-work-container-left">
                            <img src={ImgFareTrak} alt="" srcset="" />
                        </div>
                        <div className="our-work-container-right">
                            <h2>FareTrak: Bus Ticket & Tracking System</h2>
                            <p><b>FareTrak</b> is a ticketing system that supports closed (from to) and open (tap on tap off) loop operations. With an easy use interface that runs on either Android or iOS FareTrak is configured directly from the Operators reference data no setup is needed. FareTrak has a full suite of reporting and  retrieves operational data in near real time allowing for fast adoption of scheduling changes.</p>
                            <Link to="portfolio/mobile-dev-case-study" className='text-light'>VIEW CASE STUDY <i className='fa fa-long-arrow-right'></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hire-team-container"  data-aos="fade-up">
                <div className="container text-center">
                    <div className="hire-team-content">
                        <h1>Hire Dedicated Teams?</h1>
                        <p>
                            Whether you’re an enterprise or a startup looking to scale or an established company needing to extend its expertise, we can provide you with hand-picked software developers.
                        </p>
                        <Link className='btn btn-info p-2' to="/contact">Hire the Team</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default OurWork
export const OurWorkComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}