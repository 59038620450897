import React from 'react'
import IconMobile from '../../assets/images/mobile-app.png'
import IconProduct from '../../assets/images/product-development.png'
import IconSharing from '../../assets/images/sharing.png'
import IconTesting from '../../assets/images/testing.png'
import IconUIUX from '../../assets/images/ux-design.png'
import IconWeb from '../../assets/images/web-developer.png'

import { Link } from 'react-router-dom'

function WhatWeDo() {
    return (
        <div>
            <div className="what-we-do-container">
                <div className="container">
                    <div className="what-we-do-header">
                        <h1 className="heading" data-aos="fade-up">What We Do?</h1>
                        <h1 data-aos="fade-up">Creating digital products that solve real-life problems.</h1>
                        <p data-aos="fade-up">We love turning concepts into reliable and robust digital solutions, with a well-defined strategy,
                            amazing user experience and scalable technology solutions.</p>
                    </div>

                    <div className="services flex-container" data-aos="fade-up-right">
                        <div className="service">
                            <img src={IconUIUX} width="70px" height="70px" alt="UI/UX Design" />
                            <h2>UI/UX Design</h2>
                            <p>We create amazing experiences for your users through excellent UI/UX designs.</p>
                            <Link to="/services#UI-UX-Design">READ MORE ></Link>
                        </div>
                        <div className="service">
                            <img src={IconProduct} width="70px" height="70px" alt="Product Development" />
                            <h2>Product Development</h2>
                            <p>Creating aesthetic apps from scratch or revamping your existing applications.</p>
                            <Link to="/services#ProductDevelopment">READ MORE ></Link>
                        </div>
                        <div className="service">
                            <img src={IconWeb} width="70px" height="70px" alt="Web Development" />
                            <h2>Web Development</h2>
                            <p>We are expert in website design and development.</p>
                            <Link to="/services#ProductDevelopment">READ MORE ></Link>
                        </div>
                        <div className="service">
                            <img src={IconMobile} width="70px" height="70px" alt="Mobile App Development" />
                            <h2>Mobile App Development</h2>
                            <p>High-performing apps to scale your business.</p>
                            <Link to="/services#MobileAppDevelopment">READ MORE ></Link>
                        </div>
                        <div className="service">
                            <img src={IconTesting} width="70px" height="70px" alt="QA & Testing" />
                            <h2>QA & Testing</h2>
                            <p>Make your product bug free and reliable.</p>
                            <Link to="/services#QualityAssurance">READ MORE ></Link>
                        </div>
                        <div className="service">
                            <img src={IconSharing} width="70px" height="70px" alt="Resource Augmentation" />
                            <h2>Resource Augmentation</h2>
                            <p>Hire the best talent you can for your team.</p>
                            <Link to="/services">READ MORE ></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatWeDo