import React, { useState } from 'react';
import ImgQuote from '../../assets/images/left-quote.svg';

const testimonialsData = [
    {
        quote: "Cynux Solutions Pvt Ltd was able to deliver accurate and high-quality estimates for the project, much to the client's delight. The team was highly responsive, and internal stakeholders were particularly impressed with the vendor's cost-effective solutions.",
        author: "CTO, Air Access Technology"
    },
    {
        quote: "Cynux Solutions felt like a real partner in the project - invested, asking great questions to understand the vision, and adding ideas to improve the quality of the result. They worked quickly and was very pleasant to collaborate with. Will be happy to work again with them.",
        author: "Shuli Finley"
    },
    {
        quote: "Very professional. The job has been done successfully and also he has help me in other issues for the app. I will hire him again",
        author: "Jorge"
    }
    // Add more testimonials as needed
];

const Testimonial = () => {
    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    function changeTestimonial(direction) {
        const nextTestimonial = (currentTestimonial + direction + testimonialsData.length) % testimonialsData.length;
        setCurrentTestimonial(nextTestimonial);
    }

    return (
        <div className="testimonial-container">
            <div className="container">
                <div className="testimonial-part" data-aos="fade-up">
                    <div className="testimonial-start">
                        <span className="nav-arrow" onClick={() => changeTestimonial(-1)}>
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div className="testimonial-middle">
                        <img src={ImgQuote} width="40px" alt="Quote" />
                        <div className="testimonial active">
                            <div className="testimonial-quote">{testimonialsData[currentTestimonial].quote}</div>
                            <div className="testimonial-author">{testimonialsData[currentTestimonial].author}</div>
                        </div>
                    </div>
                    <div className="testimonial-start">
                        <span className="nav-arrow" onClick={() => changeTestimonial(1)}>
                            <i className="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;
