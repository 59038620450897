import React from 'react'
import ReactImg from '../../assets/images/react.png'
import ImgShopping from '../../assets/images/shopping.svg'
import ImgHealthcare from '../../assets/images/healthcare.svg'
import ImgEdtech from '../../assets/images/edtech.svg'
import ImgRealEstate from '../../assets/images/real-estate.svg'
import ImgFinTech from '../../assets/images/FinTech.svg'
import ImgTravel from '../../assets/images/travel.svg'
import ImgLogistics from '../../assets/images/logistics.svg'
import ImgManufacturing from '../../assets/images/Manufacturing.svg'
import ImgSass from '../../assets/images/saas.svg'
import ImgBuilding from '../../assets/images/building.svg'

function IndustryServe() {
    return (
        <div>
            <div className="Industry-container">
                <div className="container">
                    <div className="Industry-header" data-aos="fade-right">
                        <h1 className="heading">Industry We Serve</h1>
                        <p>We offer industry-specific digital marketing strategies. Get a personalized strategy tailored to your
                            business’s needs. Over the years, we have acquired knowledge, experience, and skills to transform
                            industries.
                        </p>
                    </div>
                    <div className="Industries" data-aos="fade-left">
                        <div className="Industry">
                            <img src={ImgShopping} width="50px" height="70px" alt="Retail & eCommerce" />
                                <h4>Retail & eCommerce</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgHealthcare} width="50px" height="50px" alt="Healthcare" />
                                <h4>Healthcare</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgEdtech} width="50px" height="50px" alt="EdTech" />
                                <h4>EdTech</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgRealEstate} width="50px" height="50px" alt="Real Estate" />
                                <h4>Real Estate</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgFinTech} width="50px" height="50px" alt="FinTech" />
                                <h4>FinTech</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgTravel} width="50px" height="50px" alt="Travel & Hospitality" />
                                <h4>Travel & Hospitality</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgLogistics} width="50px" height="50px" alt="Logistics & Supply Chain" />
                                <h4>Logistics & Supply Chain</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgManufacturing} width="50px" height="50px" alt="Manufacturing" />
                                <h4>Manufacturing</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgSass} width="50px" height="50px" alt="Sass & Technology" />
                                <h4>Sass & Technology</h4>
                        </div>
                        <div className="Industry">
                            <img src={ImgBuilding} width="50px" height="50px" alt="Small & Medium Size Businesses" />
                                <h4>Small & Medium Size Businesses</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryServe