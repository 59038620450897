import React from 'react'
import Header from '../../layouts/frontend/Header'
import Footer from '../../layouts/frontend/Footer'
import { Link } from 'react-router-dom'

function WebDevelopment() {
    return (
        <div>
            <Header
                isH1="Web Development"
                isH2=""
                isP="We design beautiful, easy-to-use mobile and web products that meet your business and users needs.
                We also work close to our dev team to ensure our designs are technically feasible within the constraints of the project."
                BgComponent={WebComponent}
                styleDrak="text-dark"
                isButtonName=""
                isButtonUrl=""
            />
            <div className=" case-study-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 text-center" data-aos="fade-right">
                            <h1>Alnabooda</h1>
                        </div>
                        <div className="col-md-6 col-sm-12" data-aos="fade-left">
                            <h5>Al Nabooda Automobiles provides luxurious showrooms, competent service centers and skilled teams that help to rediscover the joy of automobiles</h5>
                        </div>
                    </div>
                    <div className="row"  data-aos="fade-up">
                        <div className="col-12 text-center">
                            <img src="" alt="web dev img" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 text-left" data-aos="fade-right">
                            <h1  data-aos="fade-up">We don’t sell cars.
                                We create experiences.</h1>
                            <div data-aos="fade-up" className="case-study-points">
                                <h3>Industry</h3>
                                <p>Car Importer and Dealers</p>
                            </div>
                            <div data-aos="fade-up" className="case-study-points">
                                <h3>Technologies
                                </h3>
                                <p>ReactJs, Express, NodeJS, MongoDB</p>
                            </div>
                            <div data-aos="fade-up" className="case-study-points">
                                <h3>Services</h3>
                                <p>Application Development, Support & Maintenance</p>
                            </div>
                            <div data-aos="fade-up" className="case-study-points">
                                <Link to="#" className='text-danger'>VIEW WEBSITE <i className='fa fa-long-arrow-right'></i></Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" >
                            <div className="case-study-points">
                                <h3 data-aos="fade-left">The Brief</h3>
                                <p data-aos="fade-left" className='mt-4'>Al Nabooda Automobiles LLC is the exclusive authorised distributor in Dubai and Northern Emirates for Audi, Porsche and Volkswagen. These automobile marques are coveted world-over and represent a class apart from the rest. <br /> <br />
                                    Devising marketing, branding, and promotion strategies, extensive user base research, customisation, and managing exhaustive and real time data.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-left">
                            <div className="what-we-did-case-study">
                                <h1 data-aos="fade-right">What we did?</h1>
                                <p data-aos="fade-left">Cynux Solutions was able to meet the requirements of Al Nabooda Automobiles by employing the best of our technical talent, who took care of the CMS development of the site. The technical team was able to collaborate successfully with the stakeholders, marketing and CRM team to integrate the system and customise all the car brands pages showcasing the models, news and services.<br /><br />

                                    After the strategic framework was set, Cynux Solutions underwent an intensive design exploration with many conceptual directions. <br /><br />

                                    Not only was it important to have an operational front-facing website, but to alleviate some problems from their previous website design. Taking advantage of the customisable platform offered by WordPress, our developers created an easy-to-use, flexible and manageable backend system. As a result, Al Nabooda has autonomous control over the content and layout of their website.</p>
                            </div>
                        </div>
                        <div className="col-sm-12 case-study-imgs" data-aos="fade-up">
                            <img src="" alt="case study web" />
                        </div>
                        <div className="col-sm-12 col-md-6 case-study-imgs" data-aos="fade-up">
                            <img src="" alt="case study web" />
                        </div>
                        <div className="col-sm-12 col-md-6 case-study-imgs" data-aos="fade-up">
                            <img src="" alt="case study web" />
                        </div>
                        <div className="col-sm-12 case-study-imgs" data-aos="fade-up">
                            <img src="" alt="case study web" />
                        </div>
                        <div className="col-md-12 col-sm-12 text-left mt-5">
                            <div className="what-we-did-case-study">
                                <h1 data-aos="fade-right">The Result</h1>
                                <p data-aos="fade-left">The MVP was delivered in under six months – perfectly on time, without delays, yet with the updated scope of features included. Following the initial agreement, the MVP was delivered in a way that would make the handover easy. Therefore only widely available and recognized technologies were used.<br /><br />
                                    Syndication is an example of Cynux Solutions's extensive product development capabilities. We’ve worked on it from the ideation stage, helped to secure an early investment, and, finally, launched an MVP.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="testimonial-case-study" data-aos="zoom-in">
                        <h1>“Aside from their excellent technical capabilities and knowledge, their personalities shine through in everything they do. Working with them is always fun and collaborative. They take their job seriously, but still seem to enjoy what they do.”</h1>
                        <p></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default WebDevelopment

export const WebComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}