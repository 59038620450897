import React, { useEffect } from 'react'
import Footer from '../../layouts/frontend/Footer';
import Header from '../../layouts/frontend/Header'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'slick-carousel'
import ImgTom from '../../assets/images/tom.png';
import ImgCom from '../../assets/images/com.jpg';

function AboutUs() {
    let myStyle = {
        color: '#7e8085'
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            const $customerLogos = $('.customer-logos');
            if ($customerLogos.length) { // Check if the element exists
                $customerLogos.slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 1500,
                    arrows: false,
                    dots: false,
                    pauseOnHover: false,
                    responsive: [{
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 4
                        }
                    }, {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 3
                        }
                    }]
                });
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);


    let isP = (
        <>
            <div style={myStyle}>
                With a perfect blend of talent, technology, innovation, and expertise, we deliver cutting-edge
                digital solutions that help Enterprise, B2B, B2C, and SaaS companies achieve their unique business
                goals.
            </div>
        </>
    );
    return (
        <div>
            <Header
                isH1="We help brands build digital products and scale teams"
                isH2=""
                isP={isP}
                BgComponent={AboutUsComponent}
                styleDrak="text-dark"
                isButtonName="Connect with us"
                isButtonUrl="/contact"
            />
            <div className="about-first-container">
                <div className="container">
                    <div className="about-in-part-1">
                        <div className="about-1-left" data-aos="fade-right">
                            <h1>About Cynux</h1>
                        </div>
                        <div className="about-1-right" data-aos="fade-left">
                            <h1>Believe. Innovate. Inspire.</h1>
                            <h3>Cynux Solutions is a group of curious and motivated technologists, dedicated to building excellent products that have a lasting impact on the world. A culture that fosters innovation and values a happy workplace.</h3>
                            <p style={myStyle}>Today we have a lot of experience and beautifully crafted digital products behind our backs, but we never stop experimenting and playing with new technologies. We bring the best talent together to understand users, challenge ideas, prototype and iterate products. We create digital products for entrepreneurs and brands to impact the way people experience the world.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-first-container pt-0" >
                <div className="container">
                    <div className="about-in-part-1">
                        <div className="about-1-left" data-aos="fade-right">
                            <h1>What we Stand for</h1>
                        </div>
                        <div className="about-1-right" data-aos="fade-left">
                            <div className="about-2-right-in" >
                                <h1>We embrace change</h1>
                                <p style={myStyle}>Evolution taught us that only those adaptable survive. We made this part of our culture: we are constantly looking for ways to innovate, feed our curiosity and not only accept change, but provoke it.</p>
                            </div>
                            <div className="about-2-right-in" data-aos="fade-up">
                                <h1>We are the best at what we do</h1>
                                <p style={myStyle}>We keep up with the latest technology, understand market trends and help you adapt your product where it truly matters. We know the drill. We’ve been doing this since 2016.
                                </p>
                            </div>
                            <div className="about-2-right-in" data-aos="fade-up">
                                <h1>We think product-first</h1>
                                <p style={myStyle}>We focus on long-term success over short-term gains by delivering a great product and a delightful experience that users love.</p>
                            </div>
                            <div className="about-2-right-in" data-aos="fade-up">
                                <h1>We work as a team</h1>
                                <p style={myStyle}>The best results come when you have experts from different fields working towards a common goal. And that’s exactly what we do. With you. We’re all in this together.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="testimonial-page-container">
                <div className="container">
                    <div className="testimonial-page-heading">
                        <h5 style={myStyle} data-aos="fade-right">TESTIMONIALS</h5>
                        <h1 data-aos="fade-right">Here’s why companies love working with Cynux Solutions!</h1>
                    </div>
                    <div className="testimonial-page-content">
                        <div className="testimonial-content-1" data-aos="zoom-in-up">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <div className="testimonial-content-2">
                            <div className='testimonial-content-2-in' data-aos="zoom-in">
                                <p style={myStyle}>Cynux Solutions Pvt Ltd was able to deliver accurate and high-quality estimates for the project, much to the client's delight. The team was highly responsive, and internal stakeholders were particularly impressed with the vendor's cost-effective solutions.</p>
                                <h3>Tom Simms</h3>
                                <p style={myStyle}>CTO, Air Access Technology</p>
                            </div>
                        </div>
                        <div className="testimonial-content-3" data-aos="fade-down">
                            <img src={ImgTom} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="join-our-team-container">
                <div className="container">
                    <div className="join-our-team-in pb-5">
                        <div className="join-our-team-1"  data-aos="fade-right">
                            <h1>Join our Awesome Team!</h1>
                            <p>We’re always on the lookout for talent. Come say hello and we’ll figure out if you’re a good fit!</p>
                        </div>
                        <div className="join-our-team-2" data-aos="fade-left">
                            <Link className='btn btn-info p-2' to="/jobs">
                                We are Hiring Now <i className='ml-2 fa fa-long-arrow-right fa-lg '></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-first-container">
                <div className="container">
                    <div className="about-in-part-1">
                        <div className="about-1-left"  data-aos="fade-right">
                            <h1>Our Clients</h1>
                        </div>
                        <div className="about-1-right"  data-aos="fade-left">
                            <div className="about-2-right-in">
                                <h1 data-aos="fade-up">We helped bold startups and product companies design and create apps that users love</h1>
                                <p data-aos="fade-up" style={myStyle}>With years of experience, we have developed the process, system and technologies that enable us to create high-quality, scalable web and mobility solutions. <br /><br />
                                    Technology is our craft. Having 12 years of experience and our own successful consumer products help us truly understand our clients’ needs and how we can add the most value by providing expertise in the form of mobile design and tech teams. <br /><br />
                                    We proudly partnered with:</p>
                                <img data-aos="zoom-in-up" src={ImgCom} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs

export const AboutUsComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}