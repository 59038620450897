import React, { useEffect } from 'react'
import Footer from '../../layouts/frontend/Footer';
import Header from '../../layouts/frontend/Header'
import $ from 'jquery';
import 'slick-carousel'

function Jobs() {
    let myStyle = {
        color: '#7e8085'
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            const $customerLogos = $('.customer-logos');
            if ($customerLogos.length) { // Check if the element exists
                $customerLogos.slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 1500,
                    arrows: false,
                    dots: false,
                    pauseOnHover: false,
                    responsive: [{
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 4
                        }
                    }, {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 3
                        }
                    }]
                });
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    let isP = (
        <>
            <div style={myStyle}>
                We see potential, and we protect it. We see talent, and we
                nurture it. We see that you need to grow and flourish, and we will
                help bring your color into the light.
            </div>
        </>
    );
    let isButtonName = (
        <>
            Explore Open Positions <i className="fa fa-long-arrow-down fa-lg ml-2"></i>
        </>
    );

    return (
        <div>
            <Header
                isH1="Join a team of hustlers, thinkers and doers"
                isH2=""
                isP={isP}
                BgComponent={JobsComponent}
                styleDrak="text-dark"
                isButtonName={isButtonName}
                isButtonUrl="/jobs#openPositions"
            />
            <div className="jobs-container">
                <div className="container">
                    <div className="jobs-flex-1"  data-aos="fade-right">
                        <div className="jobs-container-1">
                            <h1>Our Culture</h1>
                            <p>
                                At <b>Cynux Solutions PVT. LTD.</b> we have an inclusive and collaborative work culture that inspires innovation, drives excellence, and unlocks a team’s true potential through focused development, sharing and a proactive communication. We value our team as our greatest asset and we ensure everyone is respected, recognized and celebrated.Our team is young but experienced and everyone looks out for each other while producing excellent!
                            </p>
                        </div>
                    </div>
                    <div className="jobs-flex-2">
                        <div className="jobs-container-2">
                            <div  data-aos="fade-left">
                                <h1>it is all about Cynux Solutions team</h1>
                                <p>
                                    As a company, we know that our people are the most valuable asset we have. We invest in our employees, and it shows—we work with us two times longer than the industry average.
                                </p>
                            </div>
                            <div className="jobs-table">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6" data-aos="zoom-in">
                                        <h1>Togetherness</h1>
                                        <p>The backbone of our business is the unity within our team. It takes all types of talent to do what we do, something that we never take for granted. We work hard but we also make sure that we put the tools down and spend time enjoying each other’s company.</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6" data-aos="zoom-in">
                                        <h1>Work / life balance</h1>
                                        <p>We believe our team thrives when we give them the trust and freedom to manage their workload in a way that suits them best. Despark’s work-life balance program is outstanding because we know and value important moments in our team members life.</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6" data-aos="zoom-in">
                                        <h1>Meaningful work</h1>
                                        <p>We’re crazy about digital. We live and breathe it. We help digital startups and innovators turn ideas into business. We are inspired by digital projects that have a meaningful impact on how people experience the world.</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6" data-aos="zoom-in">
                                        <h1>Ownership</h1>
                                        <p>We trust your expertise and that you have the best knowledge to make decisions in your field. We believe in the self-organization of each team and each employee. At Cynux, your opinion matters.</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6" data-aos="zoom-in">
                                        <h1>Transparency</h1>
                                        <p>We strive for complete transparency. We build trust by supporting and mentoring each other through every project stage because we are all part of the wider team. No hidden agendas, no question unanswered, no decision unjustified; that’s how we operate.</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6" data-aos="zoom-in">
                                        <h1>Endless Career Growth</h1>
                                        <p>At Cynux you decide in which direction you want to grow. You can improve your skills, learn a new programming language or even change your career path entirely. We will support you with our knowledge.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id='openPositions' className="jobs-open-pos-container pt-5">
                <div className="container ">
                    <div className="jobs-pos-heding">
                        <h1 data-aos="zoom-in-up">Open Postions</h1>
                    </div>
                    <div className="Job-posts my-4" data-aos="fade-right">
                        <div className="jobs-posts-first">
                            <h2>Senior PHP Developer</h2>
                            <p>Full Time - Ahmedabad</p>
                        </div>
                        <div className="jobs-posts-second">
                            <i className='fa fa-long-arrow-right fa-lg'></i>
                        </div>
                    </div>
                    <div className="Job-posts mb-4" data-aos="fade-right">
                        <div className="jobs-posts-first">
                            <h2>Senior MERN Stack Developer</h2>
                            <p>Full Time - Ahmedabad</p>
                        </div>
                        <div className="jobs-posts-second">
                            <i className='fa fa-long-arrow-right fa-lg'></i>
                        </div>
                    </div>
                    <div className="Job-posts mb-4" data-aos="fade-right">
                        <div className="jobs-posts-first">
                            <h2>Senior Python Developer</h2>
                            <p>Full Time - Ahmedabad</p>
                        </div>
                        <div className="jobs-posts-second">
                            <i className='fa fa-long-arrow-right fa-lg'></i>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className="jobs-slider">

                <div className="container">
                    <h2 data-aos="zoom-in">We Embrace an open work culture.</h2>
                    <section className="customer-logos slider" data-aos="zoom-in">
                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/luxury-letter-e-logo-design_1017-8903.jpg" alt="imageee" />
                        </div>

                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/3d-box-logo_1103-876.jpg" alt="imageee" />
                        </div>
                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/blue-tech-logo_1103-822.jpg" alt="imageee" />
                        </div>
                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/colors-curl-logo-template_23-2147536125.jpg" alt="imageee" />
                        </div>
                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/abstract-cross-logo_23-2147536124.jpg" alt="imageee" />
                        </div>
                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/football-logo-background_1195-244.jpg" alt="imageee" />
                        </div>
                        <div className="slide">
                            <img src="https://image.freepik.com/free-vector/background-of-spots-halftone_1035-3847.jpg" alt="imageee" />
                        </div>
                    </section>
                </div>
            </div> */}
            <div className="any-question d-flex justify-content-center align-items-center" data-aos="fade-right">
                <div className="container text-center">
                    <h1>Do You have any Questions?</h1>
                    <p>Talk with our recruitment team!</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Jobs

export const JobsComponent = () => {
    return (
        <div id="Backgrond-color" style={{ backgroundColor: '#f9f4ef' }}>
        </div>
    )
}