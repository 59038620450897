import React, { useEffect } from 'react'
import ImgMap from '../../assets/images/map.gif'

function About() {
    return (
        <div>
            <div className="container about-container">
                <div className="row">
                    <div className="about-img col-sm-12 col-md-6 col-lg-6 mb-5" data-aos="fade-right" style={{minHeight: 0}}>
                        <img src={ImgMap} width="100%" height="100%" />
                    </div>
                    <div className="about-content col-sm-12 col-md-6 col-lg-6 mb-5 p-3" data-aos="fade-left" style={{minHeight: 0}}>
                        <h1>
                            We help brands build digital products and scale teams
                        </h1>
                        <p className="mt-3">We are an extremely nimble design and development house with more than 12 years of
                            experience. We
                            differentiate ourselves by building strategic long-term partnerships with our clients and providing
                            exceptional quality services at all stages of the application development lifecycle.</p>
                    </div>
                    <div className="about-res col-sm-12 col-md-12 col-lg-12" data-aos="fade-up" style={{minHeight: 0}}>
                        <div className="row">
                            <div className="col-sm-10 col-md-3 col-lg-3 border" style={{minHeight: 0}}>
                                <div className="num">12+</div>
                                <div className="text">Year of Experience</div>
                            </div>
                            <div className="col-sm-10 col-md-3 col-lg-3 border" style={{minHeight: 0}}>
                                <div className="num">20+</div>
                                <div className="text">Skilled Professionals</div>
                            </div>
                            <div className="col-sm-10 col-md-3 col-lg-3 border" style={{minHeight: 0}}>
                                <div className="num">150+</div>
                                <div className="text">Projects Delivered</div>
                            </div>
                            <div className="col-sm-10 col-md-3 col-lg-3 border" style={{minHeight: 0}}>
                                <div className="num">100+</div>
                                <div className="text">Global Clientele Served</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About