import React from 'react'
import JavaImg from '../../assets/images/java.svg';
import ImgAngular from '../../assets/images/angular.png';
import ImgReact from '../../assets/images/react.svg';
import ImgJS from '../../assets/images/javascript.svg';
import ImgC from '../../assets/images/c.png';
import ImgPhp from '../../assets/images/php.png';
import ImgLaravel from '../../assets/images/laravel.png';
import ImgPython from '../../assets/images/python.png';
import ImgNodejs from '../../assets/images/node-js.png';
import ImgVue from '../../assets/images/vue.svg';
import ImgWordpress from '../../assets/images/wordpress.png';
import ImgShopify from '../../assets/images/shopify.svg';
import ImgHubspot from '../../assets/images/hubspot.svg';
import ImgPWA from '../../assets/images/pwa.png';
import ImgIos from '../../assets/images/iOS.svg';
import ImgAndroid from '../../assets/images/android.png';
import ImgFlutter from '../../assets/images/flutter.png';
import ImgAWS from '../../assets/images/aws.png';
import ImgMySQL from '../../assets/images/mysql.png';
import ImgSketch from '../../assets/images/sketch.png';
import ImgFigma from '../../assets/images/figma.png';
import ImgZeplin from '../../assets/images/zeplin.png';

function Technology() {
    return (
        <div>
            <div className="technology-container">
                <div className="container" >
                    <div className="technology-header" data-aos="fade-right">
                        <h1 className="heading">Technology</h1>
                        <p>We offer only well-recognized and time-tested technologies to support any of your business goals.
                        </p>
                    </div>
                    <div className="technologies"  data-aos="fade-left">
                        <div className="technology">
                            <img src={ImgAngular} width="50px" alt="Angular" />
                                <h4>ANGULAR</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgReact} width="50px" alt="REACT" />
                                <h4>REACT</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgJS} width="50px" alt="JAVASCRIPT" />
                                <h4>JAVASCRIPT</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgC} width="20px"alt="C SHARP" />
                                <h4>C SHARP</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgPhp} width="50px" alt="PHP" />
                                <h4>PHP</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgLaravel} width="50px" alt="LARAVEL" />
                                <h4>LARAVEL</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgPython} width="50px" alt="PYTHON" />
                                <h4>PYTHON</h4>
                        </div>
                        <div className="technology">
                            <img src={JavaImg} width="50px" height="70px" alt="JAVA" />
                                <h4>JAVA</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgNodejs} width="50px" alt="NODE.JS" />
                                <h4>NODE.JS</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgVue} width="50px" alt="VUE" />
                                <h4>VUE</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgNodejs} width="50px" alt="EXPRESS JS" />
                                <h4>EXPRESS JS</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgWordpress} width="50px" alt="WORDPRESS" />
                                <h4>WORDPRESS</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgShopify} width="50px" alt="SHOPIFY" />
                                <h4>SHOPIFY</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgHubspot} width="50px" alt="HUBSPOT" />
                                <h4>HUBSPOT</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgPWA} width="50px" alt="PWA" />
                                <h4>PWA</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgIos} width="50px" alt="iOS" />
                                <h4>IOS</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgAndroid} width="50px" alt="ANDROID" />
                                <h4>ANDROID</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgReact} width="50px" alt="REACT NATIVE" />
                                <h4>REACT NATIVE</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgFlutter} width="50px" alt="FLUTTER" />
                                <h4>FLUTTER</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgAWS} width="50px" alt="AWS" />
                                <h4>AWS</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgMySQL} width="50px" alt="MYSQL" />
                                <h4>MYSQL</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgSketch} width="50px" alt="SKETCH" />
                                <h4>SKETCH</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgFigma} width="50px" alt="FIGMA" />
                                <h4>FIGMA</h4>
                        </div>
                        <div className="technology">
                            <img src={ImgZeplin} width="50px" alt="ZEPLIN" />
                                <h4>ZEPLIN</h4>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Technology