import React from 'react'
import { Link } from 'react-router-dom';
import ImgCompany from '../../assets/images/com.jpg';

function Trusted() {
    return (
        <div>
            <div className="Trusted-container">
                <div className="container">
                    <div className="Trusted-header" data-aos="fade-right">
                        <h1 className="heading">Trusted By Global Brands</h1>
                        <p>From bold start-ups to big names, Cynux Solutions is proud of our client’s success and passionate about
                            helping them accelerate growth through digital.
                        </p>
                    </div>
                    <div className="Trusts" data-aos="fade-left">
                        <div className="Trusted">
                            <img src={ImgCompany}  alt="Angular Icon" />
                        </div>
                    </div>
                    <div className="get-touch-outer mt-5" data-aos="fade-up">
                        <div className="get-in-touch-container">
                            <div className="get-in-touch">
                                <p>Get in Touch</p>
                                <h1>Let's talk about your Project</h1>
                                <Link className="btn btn-info btn-info" to="/contact">START NOW <i
                                    className="fa fa-arrow-right"></i></Link>
                                {/* <button className="btn btn-info btn-lg mt-4 pt-1 pb-1 pl-3 pr-3">START NOW <i
                                    className="fa fa-arrow-right"></i></button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trusted